import new_LVgland from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import screen1 from "../images/newProductPage/latvia/1.png";
import screen2 from "../images/newProductPage/latvia/2.png";
import screen3 from "../images/newProductPage/latvia/3.png";
import screen4 from "../images/newProductPage/latvia/4.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const latvian = {
  navbar: {
    data: [
      { title: "Pasūtīt kursu", path: "/course" },
      { title: "Pieslēgties", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "Par mums", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      language: "lv",
      hero: {
        languages: [
          "angļu valodā",
          "spāņu valodā",
          "vācu valodā",
          "holandiešu valodā",
          "itāļu valodā",
          "krievu valodā",
          "poļu valodā",
          "franču valodā",
          "portugāļu valodā",
        ],
        title: "Runājiet pārliecinošāk,",
        prefix: "ar pārliecību",
        subtitle:
          "Iegūstiet valodas prasmes ar mūsu kursu, kas balstīts uz īstiem dzīves piemēriem!",
        buttonText: "Pasūtiet tagad!",
      },
      learning: {
        title: "Kā izskatās kurss?",
        config: [
          {
            image: screen1,
            category: "Apgūstiet valodu visvieglākajā veidā",
            descriptions: [
              {
                bold: "Desmitiem nodarbību",
                rest: "ar piemēriem, balstītiem uz ikdienas komunikāciju.",
              },
              {
                bold: "Praktiskas frāzes",
                rest: "izstrādājuši dzimtās valodas runātāji.",
              },
              {
                bold: "Uzdevumi,",
                rest: "kas efektīvi attīsta lasīšanu, klausīšanos un runāšanu.",
              },
            ],
          },
          {
            image: screen2,
            category: "Praktiskās nodarbības",
            description:
              "Katrs mācību līmenis sastāv no desmitiem nodarbību, kas pielāgotas jūsu vajadzībām.",
          },
          {
            image: screen3,
            category: "Uzdevumi",
            description:
              "Mūsu uzdevumi efektīvi atbalsta materiāla atkārtošanu un uzlabo atmiņu.",
          },
          {
            image: screen4,
            category: "Vārdi, frāzes un izteicieni",
            description:
              "Katra nodarbība satur rūpīgi atlasītus attēlus, kas padara mācību procesu intuitīvu.",
          },
        ],
        photoData: [screen1],
      },
      languages: {
        languages: [
          { image: new_LVgland, lang: "Angļu" },
          { image: new_french, lang: "Franču" },
          { image: new_spanish, lang: "Spāņu" },
          { image: new_niderland, lang: "Holandiešu" },
          { image: new_germany, lang: "Vācu" },
          { image: new_italy, lang: "Itāļu" },
          { image: new_russia, lang: "Krievu" },
          { image: new_portugal, lang: "Portugāļu" },
          { image: new_polish, lang: "Poļu" },
        ],
        title: "Pieejamās valodas:",
        description:
          "Sagatavojieties nākamajam ceļojumam vai pievienojiet valodas prasmes savam CV.",
      },
      expertSection: {
        title: "Jūsu valodas eksperts",
        firstSectionTitle: "Atbalsts rokas stiepiena attālumā",
        firstSectionDescription: "Saņemiet pilnu atbalstu mācību laikā!",
        secondSectionTitle: "Uzdodiet jautājumus un saņemiet atbildes",
        secondSectionDescription:
          "Jebkurus jautājumus varat uzdot tieši savam ekspertam.",
        thirdSectionTitle: "Individuāla pieeja katrā solī",
        thirdSectionDescription:
          "Eksperta padomu izmantošana paātrina jūsu mācību procesu.",
      },
      courseValue: {
        title: "Iegūstiet vairāk ar eLingo",
        subtitle: "Ne tikai valodu mācīšanās – saņemiet pilnu piekļuvi rīkiem.",
        buttonText: "Pasūtiet tagad!",
        config: [
          {
            title: "Mācības, kas pielāgotas jūsu dzīvesveidam",
            sections: [
              {
                text: "Mācieties, kur un kad vēlaties, pateicoties mūsu platformai.",
              },
              {
                text: "Īsas nodarbības lieliski iekļaujas jūsu ikdienas grafikā.",
              },
            ],
          },
          {
            title: "Motivācija katrā solī",
            sections: [
              {
                text: "Saglabājiet savu motivāciju ar mūsu izsekošanas sistēmu.",
              },
              {
                text: "Katru dienu mācoties, jūs tuvojaties valodas plūdenībai.",
              },
            ],
          },
          {
            title: "Pilnīga elastība un personalizācija",
            sections: [
              {
                text: "Pielāgojiet kursu savam līmenim un mācību tempam.",
              },
              {
                text: "Fokusējieties uz to, kas jūs visvairāk interesē.",
              },
            ],
          },
        ],
      },
    },
  },
  aboutUs: {
    hero: {
      title: "Misija",
      description:
        "Mēs ticam, ka valoda veido tiltu starp kultūrām, un mūsu misija ir pārvarēt komunikācijas barjeras un apvienot cilvēkus caur valodu izglītību.",
    },
    why: {
      title: "Kāpēc Mēs?",
      config: [
        {
          title: "Inovatīvas izglītības metodes",
          image: dlaczego1,
          description:
            "Mūsu sarežģītais un praksē pārbaudītais mācīšanās algoritms pielāgo materiālus jūsu pašreizējam zināšanu līmenim un paplašina to caur efektīvu mācīšanu.",
        },
        {
          title: "Plašs valodu klāsts",
          image: dlaczego2,
          description:
            "Mēs piedāvājam kursus pasaulē populārākajās valodās. Tas ļauj jums izvēlēties valodu, kas atbilst jūsu vajadzībām.",
        },
        {
          title: "Izglītība jautrā veidā",
          image: dlaczego3,
          description:
            "Mūsu platforma padara valodu apguvi par jautru un patīkamu pieredzi, tāpēc jūs būsiet motivēti mācīties valodas.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizācija un pieejamība",
          description:
            "Mūsu efektīvais mašīnmācīšanās algoritms nepārtraukti strādā, lai nodrošinātu atbilstoša līmeņa materiālus katram indivīdam. eLingo ļauj izglītojamajiem atklāt modeļus patstāvīgi, nekoncentrējoties uz valodas likumiem - tieši tāpat, kā jūs apguvāt savu dzimto valodu bērnībā.",
          image: zdjecie1,
        },
        {
          title: "Tehnoloģijas un zinātnes kombinācija",
          description:
            "eLingo izmanto drosmīgu tehnoloģijas un zinātnes kombināciju, lai stimulētu prātu darbībai. Neskaitāmu vārdu un izteicienu sarakstu atkārtošana ir garlaicīga, un jūsu smadzenes to zina. Mūsu metode ir aizraujoša un efektīva, liekot jums izmantot iegūtās zināšanas dažādos veidos konkrētos brīžos - jūsu smadzenes novērtēs šo risinājumu!",
          image: zdjecie2,
        },
        {
          title: "Kopiena un vērtības",
          description:
            "Valodas palīdz cilvēkiem iesaistīties. Valodu apguve veicina sapratni. eLingo kopiena apvieno cilvēkus no visas pasaules. Mēs esam ambiciozi un vienmēr pārvaramies robežas. Mēs ticam, ka valodu apguve var būt jautra, un esam ziņkārīgi izpētīt jaunas kultūras caur valodu.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Interesē kurss?",
      title1: "Vēlaties vairāk informācijas?",
      subtitle: "Atstājiet savu tālruņa numuru - mēs jums atzvanīsim.",
      subtitle1: "Ja jums jau ir konts, lūdzu, aizpildiet šādus laukus:",
      button1: "Vārds un Uzvārds",
      button2: "Tālruņa numurs",
      button3: "E-pasts",
      buttonSub: "Sūtīt",
      checkOne:
        "Iesniedzot šo veidlapu, es piekrītu noteikumiem un apliecinu, ka esmu izlasījis sekojošo",
      checkOneTwo: "",
      checkTwo: "Es piekrītu, ka ar mani sazinās mārketinga nolūkos",
      textOne: "Lūdzu, ņemiet vērā, ka",
      textTwo:
        "Jūsu personas datu pārzinis ir EOU Sp. z o.o., ar juridisko adresi Varšavā, Młynarska 42 / 115, 01-171, reģistrēts uzņēmēju reģistrā, ko uztur Varšavas rajona tiesa ar KRS5273057993, REGON: 525396900, NIP5273057993. Jūsu personas dati tiks apstrādāti saistībā ar kontaktformas pieprasījumu vai noslēgtā līguma izpildi. Sazinoties ar mums, jūs brīvprātīgi piekrītat savu personas datu apstrādei iepriekš minētajiem mērķiem. Jums ir tiesības piekļūt saviem datiem, tos labot, dzēst vai ierobežot apstrādi, iebilst pret apstrādi un datu pārnesamību. Jums arī ir tiesības jebkurā laikā atsaukt savu piekrišanu, taču piekrišanas atsaukšana neietekmē apstrādes likumību, kas balstīta uz piekrišanu pirms tās atsaukšanas. Jums arī ir tiesības iesniegt sūdzību uzraudzības iestādei. Datu sniegšana ir brīvprātīga, bet var būt nepieciešama, lai atbildētu uz pieprasījumu vai izpildītu līgumu. Personas dati tiks glabāti laika periodā, kas nepieciešams iepriekš minētajiem mērķiem, bet ne ilgāk kā vispārējo tiesību aktu prasījumu noilguma periods. Lūdzu, uzmanīgi izlasiet privātuma politiku",
      buttonMore: "Lasīt vairāk",
      buttonLess: "Lasīt mazāk",
      subOne: "noteikumus",
      subTwo: "un privātuma noteikumus.",
      buttonTXT: "Paldies",
      rulesLink: "/Regulations_LV.pdf",
      privacyLink: "/Privacy_LV.pdf",
      product: "elv",
      market: "lv",
      send_to_url: "https://crm.eou-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "lv-en",
      server_link: "https://cms.eou-assets.eu/integration/lead-from-landing",
      currency: "eur",
      price: 87,
    },
  },
  footer: {
    privacy: "Privātums",
    privacyLink: "/Privacy_LV.pdf",
    rules: "Pakalpojuma noteikumi",
    rulesLink: "/Regulations_LV.pdf",
  },
};
